import React from 'react';

import { USER_ROLES } from '../../../constants/main';
import { roleOptions } from '../../../constants/options';
import useUser from '../../../hooks/useUser';
import Checkbox from '../../UI/Checkbox';
import ErrorMessage from '../../UI/ErrorMessage';
import classes from './styles.module.scss';

const checkRoleAvailability = (targetRole, selectedRoles) => {
  const conflictRoles = {
    [USER_ROLES.PROJECT_MANAGER]: [
      USER_ROLES.SERVICE_PROVIDER,
      USER_ROLES.CUSTOMER,
    ],
    [USER_ROLES.SERVICE_PROVIDER]: [USER_ROLES.PROJECT_MANAGER],
    [USER_ROLES.CUSTOMER]: [USER_ROLES.PROJECT_MANAGER],
  };

  return conflictRoles[targetRole].some((conflictRole) =>
    selectedRoles.includes(conflictRole)
  );
};

const RoleCheckboxes = ({
  roles,
  handleSelectedRolesChange,
  isTouched,
  error,
}) => {
  const { isProjectManager } = useUser();

  let availableRoles = roleOptions;

  if (isProjectManager) {
    availableRoles = roleOptions.filter(
      (option) =>
        ![USER_ROLES.PROJECT_MANAGER, USER_ROLES.SERVICE_PROVIDER].includes(
          option.value
        )
    );
  }

  return (
    <div className={classes.RoleCheckboxes}>
      {availableRoles.map((option, index) => {
        const isDisabled = checkRoleAvailability(option.value, roles);

        return (
          <Checkbox
            key={option.value}
            name={`roles.${index}`}
            onClick={handleSelectedRolesChange}
            label={option.label}
            value={option.value}
            isChecked={roles.includes(option.value)}
            isDisabled={isDisabled}
          />
        );
      })}
      {isTouched && error && <ErrorMessage message={error} />}
    </div>
  );
};

export default RoleCheckboxes;

import React, { useState, useCallback, useMemo, useContext } from 'react';

import cn from 'classnames';

import editIcon from '../../../assets/icons/pen-white.svg';
import deleteIcon from '../../../assets/icons/trash.svg';

import {
  ENGAGEMENTS_SWITCH_OPTIONS,
  USER_ROLES,
  ENGAGEMENT_STATUS_VALUES,
} from '../../../constants/main';
import { UiContext } from '../../../context/UiContext';
import useUser from '../../../hooks/useUser';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import InfoIcon from '../../UI/InfoIcon';
import Title from './Title';
import Description from './Description';
import Status from './Status';
import Customer from './Customer';
import ProjectManager from './ProjectManager';
import HiresList from './HiresList';
import AddHiresDropdown from './AddHiresDropdown';
import Rate from './HiresList/HiresItem/Rate';
import LogHours from './LogHours';
import TotalOrLoggedHours from './HiresList/HiresItem/TotalOrLoggedHours';
import classes from './styles.module.scss';
import PrimaryButton from '../../UI/Buttons/PrimaryButton';
import ChatManagementTable from '../../ChatManagementTable';

const EDITABLE_FIELDS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
};

const ENGAGEMENT_STATUS_COLORS = {
  [ENGAGEMENT_STATUS_VALUES.ACTIVE]: '#4BD2B1',
  [ENGAGEMENT_STATUS_VALUES.PENDING]: 'rgba(135, 69, 218, 0.50)',
  [ENGAGEMENT_STATUS_VALUES.CLOSED]: '#606161',
  [ENGAGEMENT_STATUS_VALUES._ARCHIVED]: '#606161',
};

const ENGAGEMENT_USER_STATUS_VALUES = {
  ACTIVE: 'working',
  PENDING: 'on hold',
  CLOSED: 'ended',
  _ARCHIVED: 'archived',
};

const ENGAGEMENT_USER_STATUS_COLORS = {
  [ENGAGEMENT_USER_STATUS_VALUES.ACTIVE]: '#4BD2B1',
  [ENGAGEMENT_USER_STATUS_VALUES.PENDING]: 'rgba(135, 69, 218, 0.50)',
  [ENGAGEMENT_USER_STATUS_VALUES.CLOSED]: '#606161',
  [ENGAGEMENT_USER_STATUS_VALUES._ARCHIVED]: '#606161',
};

const EngagementsItem = ({
  engagement,
  serviceProviders,
  handleUpdate,
  handleServiceProviderSearchValueChange,
  serviceProviderSearchValue,
  clearServiceProviderSearchValue,
  createEngagementUserMutation,
  deleteEngagementUserMutation,
  updateEngagementUserMutation,
  deleteEngagementMutation,
  addHoursToEngagementUserMutation,
  isOpen,
  selectedTab,
  handleChangeCustomerOrPM,
  setCurrentEngagementData,
  setShouldCreateOrUpdateConversationModalOpen,
  updateConversationStatusMutation,
  deleteConversationMutation,
}) => {
  const [editableFields, setEditableFields] = useState([]);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);

  const { showDeleteModal, showConfirmActionModal } = useContext(UiContext);
  const {
    currentUser,
    isAdmin,
    isProjectManager,
    isCustomer,
    isServiceProvider,
  } = useUser();

  const { id, title, description, status } = engagement;
  const { customer } = engagement;
  const { hires } = engagement;
  const { projectManager } = engagement;

  const currentServiceProvider = useMemo(() => {
    return engagement?.hires.find(
      (serviceProvider) => serviceProvider.userId === currentUser?.id
    );
  }, [engagement, currentUser]);

  const handleEditToggle = useCallback(() => {
    setEditableFields((prevState) => {
      if (prevState.length) {
        return [];
      }

      return Object.values(EDITABLE_FIELDS);
    });
  }, []);

  const handleTitleUpdate = useCallback(
    (titleValue) => {
      handleUpdate({ id, title: titleValue });
      setEditableFields((prevState) =>
        prevState.filter((field) => field !== EDITABLE_FIELDS.TITLE)
      );
    },
    [handleUpdate]
  );

  const handleDescriptionUpdate = useCallback(
    (descriptionValue) => {
      handleUpdate({ id, description: descriptionValue });
      setEditableFields((prevState) =>
        prevState.filter((field) => field !== EDITABLE_FIELDS.DESCRIPTION)
      );
    },
    [handleUpdate]
  );

  const handleServiceProviderSelect = useCallback(
    (serviceProvider) => {
      setSelectedServiceProvider(serviceProvider);
    },
    [setSelectedServiceProvider]
  );

  const handleCreateEngagementUser = useCallback(
    ({ clientRate, serviceProviderRate, maxHours, responsibilities }) => {
      createEngagementUserMutation({
        id,
        userId: selectedServiceProvider.id,
        clientRate,
        serviceProviderRate,
        maxHours,
        responsibilities,
      });

      setSelectedServiceProvider(null);
    },
    [createEngagementUserMutation, selectedServiceProvider, id]
  );

  const handleDeleteEngagementUser = useCallback(
    (engagementUserId) => {
      deleteEngagementUserMutation({
        id: engagementUserId,
      });
    },
    [deleteEngagementUserMutation]
  );

  const handleUpdateEngagementUser = useCallback(
    ({
      id: engagementUserId,
      clientRate,
      serviceProviderRate,
      maxHours,
      responsibilities,
      status: engagementUserStatus,
      hours,
    }) => {
      updateEngagementUserMutation({
        id: engagementUserId,
        clientRate,
        serviceProviderRate,
        maxHours,
        responsibilities,
        status: engagementUserStatus,
        hours,
      });
    },
    [updateEngagementUserMutation]
  );

  const handleAddHoursToEngagementUser = useCallback(
    (hours) => {
      const formattedHours = hours < 0 ? Math.ceil(hours) : Math.floor(hours);
      const formattedMinutes = Math.round((hours % 1) * 60);

      showConfirmActionModal({
        title: `You are about to log ${formattedHours} hours ${
          formattedMinutes ? `${formattedMinutes} minutes` : ''
        }, are you sure?`,
        handleSubmit: () => {
          addHoursToEngagementUserMutation({
            id: currentServiceProvider.id,
            hours,
          });
        },
      });
    },
    [
      addHoursToEngagementUserMutation,
      currentServiceProvider,
      showConfirmActionModal,
    ]
  );

  const handleEngagementDelete = useCallback(() => {
    showDeleteModal({
      data: engagement,
      handleDelete: () => deleteEngagementMutation(engagement.id),
      type: 'engagement',
    });
  }, [showDeleteModal, engagement, deleteEngagementMutation]);

  const filteredServiceProviders = useMemo(() => {
    return serviceProviders?.filter((serviceProvider) => {
      const isCustomerAndSp = serviceProvider.id === customer.id;
      const isSelected = selectedServiceProvider?.id === serviceProvider.id;
      const isAlreadyHired = hires.find(
        (hire) => hire.user.id === serviceProvider.id
      );

      return !isCustomerAndSp && !isSelected && !isAlreadyHired;
    });
  }, [serviceProviders, customer, hires, selectedServiceProvider]);

  const isEngagementEditing = editableFields.length > 0;
  const isTitleEditable = editableFields.includes(EDITABLE_FIELDS.TITLE);
  const isDescriptionEditable = editableFields.includes(
    EDITABLE_FIELDS.DESCRIPTION
  );

  const isEditButtonVisible = isOpen && (isAdmin || isProjectManager);
  const isDeleteButtonVisible = isOpen && (isAdmin || isProjectManager);
  const isAddHiresDropdownVisible = isOpen && (isAdmin || isProjectManager);
  const isCustomerView =
    selectedTab === ENGAGEMENTS_SWITCH_OPTIONS.HIRES && isCustomer;
  const isServiceProviderView =
    selectedTab === ENGAGEMENTS_SWITCH_OPTIONS.CUSTOMERS && isServiceProvider;
  const isHiresListVisible = !isServiceProviderView;
  const archived =
    engagement.status === ENGAGEMENT_STATUS_VALUES._ARCHIVED
      ? engagement.status
      : null;
  const showCreateConversationButton =
    (isAdmin || isProjectManager) &&
    (engagement.status === ENGAGEMENT_STATUS_VALUES.ACTIVE ||
      engagement.status === ENGAGEMENT_STATUS_VALUES.PENDING) &&
    !isEditButtonVisible;

  const handleCreateOrUpdateConversationButtonClick = useCallback(
    (conversationData) => {
      setCurrentEngagementData({
        id: engagement.id,
        pm: { ...projectManager },
        customer: { ...customer },
        hires: hires.map((x) => x.user),
        conversationData,
      });
      setShouldCreateOrUpdateConversationModalOpen(true);
    },
    [engagement.id, customer, hires]
  );

  return (
    <li
      className={cn(classes.EngagementsItem, {
        [classes.open]: isOpen,
        [classes.serviceProvider]: isServiceProviderView,
      })}
    >
      <div className={cn(classes.row, classes.top)}>
        <Title
          engagementId={id}
          title={title}
          handleUpdate={handleTitleUpdate}
          isEditable={isTitleEditable}
          isEngagementEditing={isEngagementEditing}
          isEngagementOpen={isOpen}
          isAccessible={!isServiceProviderView}
          selectedTab={selectedTab}
        />
        <div className={cn(classes.row, classes.buttons)}>
          {showCreateConversationButton && (
            <PrimaryButton
              onClick={() => handleCreateOrUpdateConversationButtonClick()}
              classnames={[classes.createNewConversationButtonDesktop]}
            >
              Create New Conversation
            </PrimaryButton>
          )}
          <Status
            value={
              archived ||
              (isServiceProviderView ? currentServiceProvider?.status : status)
            }
            valuesToShow={
              isServiceProviderView
                ? ENGAGEMENT_USER_STATUS_VALUES
                : ENGAGEMENT_STATUS_VALUES
            }
            valueColors={
              isServiceProviderView
                ? ENGAGEMENT_USER_STATUS_COLORS
                : ENGAGEMENT_STATUS_COLORS
            }
            handleUpdate={(updatedStatus) =>
              handleUpdate({ id, status: updatedStatus })
            }
            hasEditPermission={isAdmin || isProjectManager}
          />

          {isEditButtonVisible && (
            <RoundButtonWithIcon
              onClick={handleEditToggle}
              classnames={[
                classes.actionButton,
                isEngagementEditing && classes.active,
              ]}
              icon={editIcon}
              iconAlt="Edit engagement"
            />
          )}

          {isDeleteButtonVisible && (
            <RoundButtonWithIcon
              onClick={handleEngagementDelete}
              classnames={[classes.actionButton]}
              icon={deleteIcon}
            />
          )}
        </div>
      </div>

      {showCreateConversationButton && (
        <div>
          <PrimaryButton
            onClick={() => handleCreateOrUpdateConversationButtonClick()}
            classnames={[classes.createNewConversationButtonMobile]}
          >
            Create New Conversation
          </PrimaryButton>
        </div>
      )}

      <div className={cn(classes.row, classes.middle)}>
        <div
          className={cn({
            [classes.hide]: !isOpen,
          })}
        >
          <Description
            description={
              isServiceProviderView
                ? currentServiceProvider?.responsibilities
                : description
            }
            handleUpdate={handleDescriptionUpdate}
            isEditable={isDescriptionEditable}
            isEngagementEditing={isEngagementEditing}
            isEngagementOpen={isOpen}
            isServiceProviderView={isServiceProviderView}
          />
        </div>
        <div
          className={cn(classes.middle, {
            [classes.serviceProviderSection]: isServiceProviderView,
          })}
        >
          <div className={cn(classes.row, classes.customerContainer)}>
            {!isCustomerView && (
              <Customer
                customer={customer}
                isEngagementOpen={isOpen}
                isServiceProviderView={isServiceProviderView}
                onEditClick={() =>
                  handleChangeCustomerOrPM(USER_ROLES.CUSTOMER)
                }
              />
            )}

            {isAddHiresDropdownVisible && (
              <div className={classes.addHiresContainer}>
                <AddHiresDropdown
                  label="Add hire"
                  items={filteredServiceProviders}
                  selectedItem={selectedServiceProvider}
                  onItemClick={handleServiceProviderSelect}
                  onResetSelectedItem={() => setSelectedServiceProvider(null)}
                  searchValue={serviceProviderSearchValue}
                  onSearch={handleServiceProviderSearchValueChange}
                  clearSearchValue={clearServiceProviderSearchValue}
                  onSubmit={handleCreateEngagementUser}
                />
              </div>
            )}
          </div>

          {isServiceProviderView && (
            <div className={cn(classes.row, classes.ratesContainer)}>
              <Rate title="Your Billable Rate">
                ${currentServiceProvider?.serviceProviderRate} / hour
              </Rate>

              <Rate title="Max Billable Hours">
                {currentServiceProvider?.maxHours || 0} / week
              </Rate>

              <TotalOrLoggedHours
                hours={
                  (currentServiceProvider?.maxHours || 0) -
                  (currentServiceProvider?.availableHours || 0)
                }
              />

              <TotalOrLoggedHours
                hours={currentServiceProvider?.totalHours || 0}
                isTotal
              />
            </div>
          )}

          {isHiresListVisible && (
            <HiresList
              engagementStatus={status}
              hires={hires}
              handleDeleteEngagementUser={handleDeleteEngagementUser}
              handleUpdateEngagementUser={handleUpdateEngagementUser}
              isEngagementOpen={isOpen}
            />
          )}

          <ProjectManager
            projectManager={projectManager}
            isEngagementOpen={isOpen}
            onEditClick={() =>
              handleChangeCustomerOrPM(USER_ROLES.PROJECT_MANAGER)
            }
          />

          {isServiceProviderView && (
            <div className={classes.billingContainer}>
              <LogHours
                className={classes.logHours}
                onAddHours={handleAddHoursToEngagementUser}
                serviceProviderStatus={currentServiceProvider?.status || ''}
              />
              <div className={classes.billingRateInfo}>
                <InfoIcon className={classes.infoIcon} />
                <p className={classes.text}>
                  Note: The customer will see a different billing rate based on
                  any LevelDo markup percentages and fees in accordance with the
                  LevelDo.Pros service agreement. Negotiating or discussing
                  billing rates outside of the agreement with customers is
                  strictly prohibited. Direct all customers to contact LevelDo.
                  Pros support to discuss engagement billing & rates.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {(isAdmin || currentUser.id === engagement.projectManager.id) &&
        !isOpen && (
          <ChatManagementTable
            conversations={engagement.conversations}
            conversationUpdateHandler={
              handleCreateOrUpdateConversationButtonClick
            }
            updateConversationStatusMutation={updateConversationStatusMutation}
            deleteConversationMutation={deleteConversationMutation}
          />
        )}
    </li>
  );
};

export default EngagementsItem;

import React, { useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { TbMessageCircle, TbMessageCircleOff } from 'react-icons/tb';
import { RiDeleteBin6Line } from 'react-icons/ri';
import classes from './styles.module.scss';
import Table from '../Table';
import GroupButtons from '../UI/Buttons/GroupButtons';
import { UiContext } from '../../context/UiContext';
import useUser from '../../hooks/useUser';

const columnDef = [
  {
    label: 'Description',
    accessor: 'description',
    sortable: false,
  },
  {
    label: 'Conversation Members',
    accessor: 'members',
    sortable: false,
    tdClass: classes.members,
    renderer: (value) => {
      return value.map((x) => <span key={x.user.id}>{x.user.name}</span>);
    },
  },
];

const ChatManagementTable = ({
  conversations,
  conversationUpdateHandler,
  updateConversationStatusMutation,
  deleteConversationMutation,
}) => {
  const { currentUser, isAdmin, isProjectManager } = useUser();
  const { showConfirmActionModal } = useContext(UiContext);

  const withActionColumnDef = useMemo(
    () => [
      {
        label: 'Chats',
        sortable: false,
        renderer: (value, row) => {
          return row.members
            .map((x) => x.user.name)
            .includes(currentUser.name) ? (
            <Link to={`/chat/${row.conversationId}`}>Go To Chat</Link>
          ) : (
            'Go To Chat'
          );
        },
      },
      ...columnDef,
      {
        label: 'Actions',
        sortable: false,
        thClass: classes.thactions,
        tdClass: classes.actions,
        // eslint-disable-next-line no-unused-vars
        renderer: (value, row) => {
          return (
            <>
              <GroupButtons
                buttonInfo={[
                  ...((isAdmin ||
                    (isProjectManager &&
                      row.members
                        .map((x) => x.user.id)
                        .includes(currentUser.id))) && [
                    {
                      type: 'PRIMARY',
                      variant: 'transparent',
                      content: <FiEdit size={18} />,
                      onClick: () => {
                        conversationUpdateHandler({
                          id: row.conversationId,
                          title: row.title,
                          description: row.description,
                          avatar: row.avatarPath,
                          members: row.members.map((x) => x.user),
                        });
                      },
                    },
                  ]),
                  ...(isAdmin
                    ? [
                        {
                          type: 'WARNING',
                          variant: 'transparent',
                          content: row.readonly ? (
                            <TbMessageCircleOff size={20} />
                          ) : (
                            <TbMessageCircle size={20} />
                          ),
                          onClick: () => {
                            showConfirmActionModal({
                              title: `You are about to ${
                                row.readonly
                                  ? 're-activate conversation'
                                  : 'mark conversation as readonly'
                              }, are you sure?`,
                              handleSubmit: () => {
                                updateConversationStatusMutation({
                                  conversationId: row.conversationId,
                                  action: row.readonly ? 1 : 0,
                                });
                              },
                            });
                          },
                        },
                        {
                          type: 'DANGER',
                          variant: 'transparent',
                          content: <RiDeleteBin6Line size={20} />,
                          onClick: () => {
                            showConfirmActionModal({
                              title: `You are about to delete conversation, are you sure?`,
                              handleSubmit: () => {
                                deleteConversationMutation(row.conversationId);
                              },
                            });
                          },
                        },
                      ]
                    : []),
                ]}
              />
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <div className={classes.ChatManagementTable}>
      <div className={classes.tableContainer}>
        <Table
          idColumn="conversationId"
          columns={withActionColumnDef}
          loading={false}
          data={conversations}
          emptyText="No conversation"
          emptyClass={classes.empty}
        />
      </div>
    </div>
  );
};

export default ChatManagementTable;
